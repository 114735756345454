import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Spin, Row, Col, Modal, message } from 'antd';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from '../../login/container';
import StudentHome from '../../student-home/container';
import CoordinatorHome from '../../coordinator-home/container';

class App extends Component {
	renderHome = () => {
		const { authedUser } = this.props;

		if (authedUser) {
			if (authedUser.isStudent) {
				return <StudentHome />
			} else if (authedUser.isCoordinator) {
				return <CoordinatorHome />
			}
		} else {
			return <Redirect to="/login" />
		}
	}

	renderLoadingLogin = () => {
		const loadIcon = (<Icon type="loading" style={{ fontSize: 72 }} spin />)

		return (
			<Row type="flex" justify="center" align="middle" style={{ minHeight: 'inherit' }}>
				<Col>
					<Spin indicator={loadIcon} />
				</Col>
			</Row>
		)
	}

	renderLogin = () => {
		const { authedUser } = this.props;

		if (!authedUser || Object.keys(authedUser).length === 0) {
			return <Login />
		} else {
			return <Redirect to="/" />
		}
	}

	componentDidMount () {
		this.props.fetchLogin();
	}

	render () {
		const { isChekingLoggedUser } = this.props;

		if (isChekingLoggedUser) {
			return this.renderLoadingLogin();
		}

		return (
			<BrowserRouter>
				<Switch>
					<Route exact path="/login" render={this.renderLogin} />
					<Route path="/" render={this.renderHome} />
				</Switch>
			</BrowserRouter>
		)
	}
};

App.propTypes = {
	login: PropTypes.shape({
		authedUser: PropTypes.object,
	})
}

export default App;