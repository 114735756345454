import * as AppActions from '../modules/app/actions';
import { handleLogout } from '../modules/login/actions';
import ExpiredAccessError from '../errors/ExpiredAccessError';

const errorHandlerMW = store => next => action => {
	const { dispatch } = store;
	const { error } = action;
	
	if (error && error instanceof ExpiredAccessError) {
		dispatch(handleLogout());
	} 
	
	if (error) {

		if (error.isModal) {
			dispatch(AppActions.modalShowError(error));
		}

		if (error.isMessage) {
			dispatch(AppActions.messageShowError(error));
		}
	}

	next(action);
}

export default errorHandlerMW;