import App from './components/App';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as API from '../../REST-API';
import * as States from '../../request-states';

import * as LoginActions from '../login/actions';

function mapStateToProps (state) {
	const { modal, message } = state.app;
	const { loginState, authedUser } = state.login;

	const isChekingLoggedUser = loginState === States.LOADING && API.hasCredentials();
	return { isChekingLoggedUser, authedUser, modal, message }
}

function mapDispatchToProp (dispatch) {
	return bindActionCreators({
		hasCredentials: API.hasCredentials,
		fetchLogin: LoginActions.handleFetchLogin,
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProp)(App);
