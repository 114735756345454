import * as Actions from './actions';
import * as States from '../../request-states';

const init = {
	fetchState: States.IDLE,
	report: null
}

export default function reducer (state=init, action) {
	switch (action.type) {
		case Actions.FETCH_STUDENT_ACCESS_REPORT_START:
			return {
				...state,
				fetchState: States.LOADING,
			}
		case Actions.FETCH_STUDENT_ACCESS_REPORT_SUCCESS:
			return {
				...state,
				fetchState: States.SUCCESS,
			}
		case Actions.FETCH_STUDENT_ACCESS_REPORT_FAIL:
			return {
				...state,
				fetchState: States.FAIL,
				error: action.error
			}
		case Actions.SET_STUDENT_ACCESS_REPORT:
			return {
				...state,
				report: action.accessReport,
			}
		case Actions.RESET_STUDENT_ACCESS_REPORT:
			return Object.assign({}, init);
		default:
			return state;
	}
}