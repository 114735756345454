import React, { Component } from 'react';
import { Card, Form, Button, Divider, Input, Icon, Select } from 'antd';

const { Option } = Select

class LoginBox extends Component {
	handleSubmit = (event) => {
		event.preventDefault();

		this.props.form.validateFields((err, values) => {
			if (err) { return }
			const {email, password, profile} = values;
			this.props.onLogin({ email: email.trim(), password, profile });
		})
	}

	renderEmailInput () {
		const { getFieldDecorator } = this.props.form;

		const emailIcon = <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
		const emailInput = (<Input
			prefix={emailIcon}
			placeholder="Digite seu e-mail"
			disabled={this.props.isLogingIn}
		/>)
		const emailInputDecorationOptions = {
			initialValue: '',
			rules: [{ required: true, message: 'Por favor, insira seu endereço de e-mail' }]
		}

		const decoratedEmailInput = getFieldDecorator('email', emailInputDecorationOptions)(emailInput)

		return decoratedEmailInput;
	}

	renderPasswordInput () {
		const { getFieldDecorator } = this.props.form;

		const passwordIcon = <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
		const passwordInput = (<Input
			prefix={passwordIcon}
			type="password"
			placeholder="Digite sua senha"
			disabled={this.props.isLogingIn}
		/>)
		const passwordInputDecorationOptions = {
			initialValue: '',
			rules: [{ required: true, message: 'Por favor, insira sua senha' }]
		}
		const decoratedPasswordInput = getFieldDecorator('password', passwordInputDecorationOptions)(passwordInput)

		return decoratedPasswordInput;
	}

	renderProfileSelector () {
		const { getFieldDecorator } = this.props.form;

		const profileSelect = (
			<Select
				placeholder="Selecione o perfil de aluno ou de coordenador"
				allowClear={false}
				disabled={this.props.isLogingIn}
			>
				<Option value="student">Aluno</Option>
				<Option value="coordinator">Professor</Option>
				<Option value="coordinator">Coordenador</Option>
			</Select>
		);
		const profileSelectDecorationOptions = {
			initialValue: 'student',
			rules: [{ required: true, message: 'Por favor, indique se é aluno ou coordenador' }]
		}
		const decoratedProfileSelect = getFieldDecorator('profile', profileSelectDecorationOptions)(profileSelect)

		return decoratedProfileSelect;
	}

	render() {
		const { isLogingIn, form } = this.props;

		const profileSelect = form.getFieldValue('profile');

		return (
			<Card style={{boxShadow: '0px 10px 20px 1px rgba(0,0,0,0.1)'}}>
				<Form onSubmit={this.handleSubmit}>
					<Form.Item label="E-mail">
						{this.renderEmailInput()}
					</Form.Item>

					<Form.Item label="Senha">
						{this.renderPasswordInput()}
					</Form.Item>

					<Form.Item label="Perfil">
						{this.renderProfileSelector()}
					</Form.Item>

					{profileSelect === 'student' &&
						<p>Atenção Aluno: Se você logou por Facebook ou Google no aplicativo e não possui uma senha de acesso, <a href="https://web.oabdebolso.com/signUp" target="_blank" rel="noopener noreferrer">clique aqui</a>.</p>
					}

					<Divider />

					<Button
						block={true}
						type="primary"
						htmlType="submit"
						disabled={isLogingIn}
						loading={isLogingIn}
					>
						{ isLogingIn ? 'Logando...' : 'Login' }
					</Button>
				</Form>
			</Card>
		);
	}
}

export default Form.create({})(LoginBox);