import BaseError from './BaseError';

class InvalidCredentialsError extends BaseError {
	constructor() {
		const message = 'Credenciais inválidas';
		const content = 'E-mail ou senha incorretos. Por favor, verifique-os e tente novamente';
		const messageDuration = 15;

		super({ message, content, messageDuration });

		this.setMessage();
		this.setLevelError();
	}
}

export default InvalidCredentialsError;