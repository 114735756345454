import React, { Component } from 'react';
import { Row, Col, Card, Table } from 'antd';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import AbilitiesRadar from '../../abilities-radar/components/AbilitiesRadar';

class AccessReportSummaryCard extends Component {
	render () {
		const {
			range=0,
			startDate,
			finalDate,
			studentAmount=0,
			expectedApprocedAmount=0,
			meanAppAccessDays=0,
			meanAppAccessRatio=0,
			meanAbilities={},
		} = this.props;

		const averageAppAccess = `${meanAppAccessDays.toFixed(0)} (${(meanAppAccessRatio * 100).toFixed(1)}%)`;

		const options = {
			legend: {
				display: false
			}
		}

		const pieData = {
			labels: ['Total esperado de Aprovados', 'Total esperado de Reprovados'],
			datasets: [{
				data: [expectedApprocedAmount, studentAmount - expectedApprocedAmount],
				backgroundColor: ['rgba(17, 37, 70, 0.4)', 'rgba(200, 200, 200, 0.2)'],
				hoverBackgroundColor: ['rgba(17, 37, 70, 1)', 'rgba(200, 200, 200, 0.8)']
			}]
		};

		const summaryColumns = [
			{ title: 'Total de dias no período', dataIndex: 'range', key: 'range' },
			{ title: 'Total de alunos exibidos', dataIndex: 'studentAmount', key: 'studentAmount' },
			{ title: 'Total esperado de Aprovados', dataIndex: 'expectedApprocedAmount', key: 'expectedApprocedAmount'},
			// { title: 'Acesso Médio dos Estudantes em dias', dataIndex: 'averageAppAccess', key: 'averageAppAccess'}
		];

		const summaryData = [{
			key: range,
			range,
			studentAmount,
			expectedApprocedAmount,
			averageAppAccess
		}]

		return (
			<Card>
				<h2>Resumo dos Dados</h2>
				<p>De {startDate} à {finalDate}</p>

				<Row type="flex" justify="space-around">
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Table
							bordered
							pagination={false}
							columns={summaryColumns}
							dataSource={summaryData}
						/>
					</Col>
				</Row>

				<Row type="flex" justify="space-around" style={{marginTop: 30, marginBottom: 30}}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<Pie data={pieData} options={options} />
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<AbilitiesRadar
							abilities={meanAbilities}
							title="Habilidade média"
							multiplier={100}
							decimal={0}
						/>
					</Col>
				</Row>
			</Card>
		)
	}
}

AccessReportSummaryCard.propTypes = {
	range: PropTypes.number,
	studentAmount: PropTypes.number,
	meanAppAccessDays: PropTypes.number,
	meanAppAccessRatio: PropTypes.number,
	meanAbilities: PropTypes.object,
}

export default AccessReportSummaryCard;