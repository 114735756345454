import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Form, DatePicker, Card, Button, Modal } from 'antd';

const { RangePicker } = DatePicker;

class AccessReportFilterCard extends Component {
	handleChangeRange = (date) => {
		this.props.onDateChanges(date[0], date[1]);
	}

	handleSubmitFilters = event => {
		event.preventDefault();
		
		const {
			startDate,
			endDate,
			dateFormat='DD/MM/YYYY',
		} = this.props;

		Modal.confirm({
			title: 'Atenção',
			content: `Você está filtrando no período de ${startDate.format(dateFormat)} até ${endDate.format(dateFormat)}. Este período está correto?`,
			okText: 'Sim',
			cancelText: 'Cancelar',
			onOk: this.props.onApplyFilters,
			onCancel() {}
		});
	}

	render () {
		const {
			startDate,
			endDate,
			dateFormat='DD/MM/YYYY',
			isLoading=false,
			disabled=false
		} = this.props;

		const value = [startDate, endDate]

		const tomorrow = Moment().add(1, 'day')

		return (
			<Card>
				<h2>Filtros</h2>
				<Form onSubmit={this.handleSubmitFilters}>
					<Row type="flex" justify="center">
						<Col>
							<Form.Item>
								<RangePicker
									disabled={isLoading}
									value={value}
									format={dateFormat}
									allowClear={false}
									onChange={this.handleChangeRange}
									disabledDate={date => date.isSameOrAfter(tomorrow)}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row type="flex" justify="center">
						<Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={4}>
							<Button
								disabled={disabled}
								type="primary"
								loading={isLoading}
								block
								htmlType="submit"
							>
									Aplicar filtros
							</Button>
						</Col>
					</Row>
				</Form>
			</Card>
		)
	}
}

AccessReportFilterCard.propTypes = {
	startDate: PropTypes.objectOf(Moment).isRequired,
	endDate: PropTypes.objectOf(Moment).isRequired,
	dateFormat: PropTypes.string,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
}

export default AccessReportFilterCard;