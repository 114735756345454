import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Form, DatePicker, Select, Card, Button } from 'antd';

const { Option } = Select;

class AccessReportFilterCard extends Component {
	handleChangeInstitution = (institutionId) => {
		const institution = this.props
			.institutions
			.find(institution => institution.id === institutionId);

		this.props.onInstitutionChange(institution);
	}

	handleChangeSemester = (semester) => {
		this.props.onSemesterChange(semester);
	}

	handleChangeStartDate = (date) => {
		this.props.onStartDateChange(date);
	}

	handleChangeEndDate = (date) => {
		this.props.onEndDateChange(date);
	}

	renderInstitutionOptions = () => {
		return this.props.institutions.map(institution => {
			return <Option key={institution.id} value={institution.id}>{institution.name}</Option>
		})
	}

	renderSemestersOptions = () => {
		return this.props.semesters.map(semester => {
			return <Option key={semester} value={semester}>{semester}</Option>
		})
	}

	render () {
		const {
			institution,
			startDate,
			endDate,
			dateFormat,
			isLoading,
			disabled,
			onApplyFilters
		} = this.props;

		const institutionId = institution ? institution.id : null;

		return (
			<Card>
				<h2>Filtros</h2>
				<Form onSubmit={onApplyFilters}>
					<Row gutter={{md: 16}}>
						<Col md={6} sm={24}>
							<Form.Item label="Selecione a Unidade:">
								<Select disabled={isLoading} allowClear={true} value={institutionId} onChange={this.handleChangeInstitution}>
									{this.renderInstitutionOptions()}
								</Select>
							</Form.Item>
						</Col>
						<Col md={6} sm={24}>
							<Form.Item label="Data de início:">
								<DatePicker
									disabled={isLoading}
									value={startDate}
									format={dateFormat}
									disabledDate={(date) => date >= endDate.clone().subtract(1, 'day')}
									placeholder="Selecione a data de início"
									onChange={this.handleChangeStartDate}
								/>
							</Form.Item>
						</Col>
						<Col md={6} sm={24}>
							<Form.Item label="Data de fim:">
								<DatePicker
									disabled={isLoading}
									value={endDate}
									format={dateFormat}
									placeholder="Selecione a data de fim"
									onChange={this.handleChangeEndDate}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row type="flex" justify="center">
						<Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={4}>
							<Button
								disabled={disabled}
								type="primary"
								loading={isLoading}
								block
								onClick={onApplyFilters}
							>
									Aplicar filtros
							</Button>
						</Col>
					</Row>
				</Form>
			</Card>
		)
	}
}

AccessReportFilterCard.propTypes = {
	onInstitutionChange: PropTypes.func,
	onSemesterChange: PropTypes.func,
	onStartDateChange: PropTypes.func,
	onEndDateChange: PropTypes.func,
	institutions: PropTypes.array,
	semesters: PropTypes.array
}

export default AccessReportFilterCard;