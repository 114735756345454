import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as State from '../../request-states';

import Home from './components/Home';

import * as LoginActions from '../login/actions';
import * as InstitutionsActions from '../institutions/actions';
import * as AccessReportsActions from '../coordinator-access-report/actions';

function mapStateToProps(state) {
	const { authedUser } = state.login;
	const allInstitutions = Object.values(state.institutions.byId);

	const isLoadingInstitutions = state.institutions.fetchInstitutionsState === State.LOADING;
	const hasLoadedInstitutions = state.institutions.fetchInstitutionsState === State.SUCCESS;

	const isLoading = isLoadingInstitutions;
	const hasLoaded = hasLoadedInstitutions;

	return {
		authedUser,
		institutions: allInstitutions,
		hasLoaded,
		isLoading
	}
}

function mapDispatchToProps (dispatch) {
	return bindActionCreators({
		logout: LoginActions.handleLogout,
		loadInstitutions: InstitutionsActions.handleFetchInstitutions,
		loadAppAccessEvents: AccessReportsActions.handleFetchAccessReports
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
