import { Modal, message } from "antd";

export const CLOSE_ALL = 'APP_CLOSE_ALL';

export const MODAL_SHOW_ERROR = 'APP_MODAL_SHOW_ERROR';
export const MODAL_CLOSE = 'APP_MODAL_CLOSE';

export const MESSAGE_SHOW_ERROR = 'APP_MESSAGE_SHOW_ERROR';
export const MESSAGE_CLOSE = 'APP_MESSAGE_CLOSE';

///////////////////////////////////////////////////////////////////////////////////
//                                                                               //
// Modal actions                                                                 //
//                                                                               //
///////////////////////////////////////////////////////////////////////////////////

function createModalNode (error, dispatch) {
	const { level, title, content } = error;

	return Modal[level]({
		title,
		content,
		onCancel: () => dispatch(modalClose()),
		onOk: () => dispatch(modalClose())
	});
}

export function modalShowError(error) {
	return dispatch => {
		const node = createModalNode(error, dispatch);
		const close = () => node.destroy();

		dispatch({
			type: MODAL_SHOW_ERROR,
			modal: { error, node, close }
		})
	}
}

export function modalClose () {
	return { type: MODAL_CLOSE }
}

///////////////////////////////////////////////////////////////////////////////////
//                                                                               //
// Messages actions                                                              //
//                                                                               //
///////////////////////////////////////////////////////////////////////////////////

function createMessageNode (error, dispatch) {
	const { level, content, messageDuration } = error;

	return message[level](
		content,
		messageDuration,
		() => dispatch(messageClose())
	);
}

export function messageShowError(error) {
	return dispatch => {
		const node = createMessageNode(error, dispatch);
		const close = () => node();

		dispatch({
			type: MESSAGE_SHOW_ERROR,
			message: { error, node, close }
		})
	}
}

export function messageClose () {
	return { type: MESSAGE_CLOSE }
}

export function closeAll () {
	return { type: CLOSE_ALL }
}