import * as Actions from './actions';
import * as States from '../../request-states';

const init = {
	loginState: States.IDLE,
	loginError: null
}

function reducer (state=init, action) {
	switch(action.type) {
		case Actions.LOGIN_RESET:
			return init;
		case Actions.LOGIN_START:
			return {
				...state,
				loginState: States.LOADING
			};
		case Actions.LOGIN_SUCCESS:
			const jwt = action.payload;

			return {
				...state,
				loginState: States.SUCCESS,
				loginError: undefined,
				jwt,
			};
		case Actions.LOGIN_FAIL:
			return {
				...state,
				loginState: States.FAIL,
				loginError: action.error
			};
		case Actions.SET_AUTHED_USER:
			return {
				...state,
				authedUser: Object.assign({}, action.user)
			};
		case Actions.CLEAR_AUTHED_USER:
			return {
				...state,
				authedUser: null
			};
		default:
			return state;
	}
}

export default reducer;