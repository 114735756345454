import * as Actions from './actions';

const init = {
	modal: {
		error: null,
		node: null,
		close: () => {}
	},
	message: {
		error: null,
		node: null,
		close: () => {}
	},
}

export default function reducer (state=init, action) {
	switch (action.type) {
		case Actions.MODAL_SHOW_ERROR:
			state.modal.close();
			return {
				...state,
				modal: action.modal,
			}
		case Actions.MODAL_CLOSE:
			state.modal.close();
			return { ...state }
		case Actions.MESSAGE_SHOW_ERROR:
			state.message.close();
			return {
				...state,
				message: action.message,
			}
		case Actions.MESSAGE_CLOSE:
			state.message.close();
			return { ...state }
		case Actions.CLOSE_ALL:
			state.modal.close();
			state.message.close();
			return { ...state };
		default:
			return state;
	}
}