import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';

import authCheckerMW from '../middlewares/auth-check';
import errorHandlerMW from '../middlewares/error-handler';

import appReducer from '../modules/app/reducer';
import loginReducer from '../modules/login/reducer';
import institutionsReducer from '../modules/institutions/reducer';
import coordinatorAccessReportsReducer from '../modules/coordinator-access-report/reducer';
import studentAccessReportReducer from '../modules/student-access-report/reducer';

const reducers = combineReducers({
	app: appReducer,
	login: loginReducer,
	institutions: institutionsReducer,
	coordinatorAccessReports: coordinatorAccessReportsReducer,
	studentAccessReport: studentAccessReportReducer
})

const store = createStore(
	reducers,
	applyMiddleware(
		thunk,
		authCheckerMW,
		errorHandlerMW
	)
)

export default (() => store);