import * as API from '../../REST-API';

export const SET_ACCESS_REPORTS = 'SET_ACCESS_REPORTS';
export const RESET_ACCESS_REPORTS = 'RESET_ACCESS_REPORTS';
export const FETCH_ACCESS_REPORTS_START = 'FETCH_ACCESS_REPORTS_START';
export const FETCH_ACCESS_REPORTS_SUCCESS = 'FETCH_ACCESS_REPORTS_SUCCESS';
export const FETCH_ACCESS_REPORTS_FAIL = 'FETCH_ACCESS_REPORTS_FAIL';

export function setAccessReports (accessReports) {
	return {
		type: SET_ACCESS_REPORTS,
		accessReports
	}
}

export function resetAccessReports () {
	return { type: RESET_ACCESS_REPORTS }
}

function fetchAccessReportsStart () {
	return { type: FETCH_ACCESS_REPORTS_START }
}

function fetchAccessReportsSuccess () {
	return { type: FETCH_ACCESS_REPORTS_SUCCESS }
}

function fetchAccessReportsFail (error) {
	return {
		type: FETCH_ACCESS_REPORTS_FAIL,
		error
	}
}

export function handleFetchAccessReports ({ institutions, semesters, from, until }) {
	return dispatch => {
		dispatch(resetAccessReports());
		dispatch(fetchAccessReportsStart());
		API.getAccessReports({ institutions, semesters, from, until })
			.then(accessReports => {
				dispatch(setAccessReports(accessReports))
				dispatch(fetchAccessReportsSuccess())
			})
			.catch(error => {
				dispatch(fetchAccessReportsFail(error))
			})
	}
}
