import * as Utils from '../../utils';
import * as Actions from './actions';
import * as States from '../../request-states';

const init = {
	fetchInstitutionsState: States.IDLE,
	byId: {},
	ids: []
}

export default function reducer (state=init, action) {
	switch (action.type) {
		case Actions.FETCH_INSTITUTIONS_START:
			return {
				...state,
				fetchInstitutionsState: States.LOADING,
			}
		case Actions.FETCH_INSTITUTIONS_SUCCESS:
			return {
				...state,
				fetchInstitutionsState: States.SUCCESS,
			}
		case Actions.FETCH_INSTITUTIONS_FAIL:
			return {
				...state,
				fetchInstitutionsState: States.FAIL,
				error: action.error
			}
		case Actions.SET_INSTITUTIONS:
			return {
				...state,
				byId: Utils.mapById(action.institutions),
				ids: action.institutions.map(student => student.id)
			}
		case Actions.RESET_INSTITUTIONS:
			return Object.assign({}, init);
		default:
			return state;
	}
}