import * as API from '../../REST-API';
import UnrecognizedProfileError from '../../errors/UnrecognizedProfileError';

import * as InstitutionsActions from '../institutions/actions';
import * as StudentAccessReportActions from '../student-access-report/actions';
import * as CoordinatorAccessReportActions from '../coordinator-access-report/actions';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_AUTHED_USER = 'SET_AUTHED_USER';
export const CLEAR_AUTHED_USER = 'CLEAR_AUTHED_USER';

function loginStart () {
	return { type: LOGIN_START }
}

function loginReset () {
	return { type: LOGIN_RESET }
}

function loginSuccess ({email, payload}={}) {
	return {
		type: LOGIN_SUCCESS,
		email, payload
	}
}

function loginFail ({email, error}={}) {
	return {
		type: LOGIN_FAIL,
		email, error
	}
}

export function setAuthedUser ({ user }={}) {
	const type = SET_AUTHED_USER;
	return { type, user };
}

export function clearAuthedUser () {
	const type = CLEAR_AUTHED_USER;
	return { type };
}

export function handleLogin ({email, password, profile}={}) {
	return dispatch => {
		dispatch(loginStart())

		API.signIn({email, password, profile})
			.then(data => {
				const jwt = data.jwt;

				let user;

				switch (profile) {
					case 'student':
						user = data.student;
						user.isStudent = true;
						break;
					case 'coordinator':
						user = data.coordinator;
						user.isCoordinator = true;
						break;
					default:
						throw new UnrecognizedProfileError(profile);
				}

				dispatch(loginSuccess({email, payload: jwt}))
				dispatch(setAuthedUser({ user }));
			})
			.catch(error => {
				dispatch(loginFail({email, error}))
				dispatch(clearAuthedUser());
			})
	}
}

export function handleLogout () {
	return dispatch => {
		API.signOut();
		dispatch(clearAuthedUser());
		dispatch(InstitutionsActions.resetInstitutions());
		dispatch(StudentAccessReportActions.resetAccessReports());
		dispatch(CoordinatorAccessReportActions.resetAccessReports());
	}
}

export function handleFetchLogin () {
	return dispatch => {
		dispatch(loginStart());
		API.getLogin()
			.then(({data}) => {
				let user;

				if (data.student) {
					user = data.student;
					user.isStudent = true;
				} else if (data.coordinator) {
					user = data.coordinator;
					user.isCoordinator = true;
				}

				dispatch(loginSuccess({ email: user.email }))
				dispatch(setAuthedUser({ user }));
			})
			.catch(error => {
				dispatch(clearAuthedUser());
				dispatch(loginReset())
			})
	}
}