import Moment from 'moment';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as States from '../../../request-states';

import AccessReportCard from './AccessReportCard';
import AccessReportFilterCard from './AccessReportFilterCard';

const dateFormat = 'DD/MM/YYYY';

class AccessReport extends Component {
	constructor (props) {
		super(props);

		const endDate = Moment();
		const startDate = endDate.clone().subtract(1, 'month');

		this.state = {
			filters: { startDate, endDate }
		}
	}

	onDateFilterChange = (startDate, endDate) => {
		this.setState(prev => {
			const { filters } = prev;
			return { filters: { ...filters, startDate, endDate } }
		})
	}

	applyFilters = () => {
		const { startDate, endDate } = this.state.filters;

		const from = startDate.toISOString();
		const until = endDate.toISOString();

		this.props.loadAccessReports({ from, until, studentId: this.props.login.authedUser.id });
	}

	renderReportCard = (report) => {
		if (!report) {
			return ''
		}

		return (
			<Row type="flex" justify="center" style={{marginTop: 20}}>
				<Col span={23}>
					<AccessReportCard report={report} />
				</Col>
			</Row>
		)
	}

	render() {
		const { filters } = this.state;
		const { startDate, endDate } = filters;

		const { report, fetchState } = this.props;

		const isLoading = fetchState === States.LOADING;

		return (
			<Row style={{minHeight: 'inherit'}}>
				<Col span={24}>
					<Row>
						<Col xs={24}>
							<h1>Relatório Acesso</h1>
						</Col>
					</Row>

					<Row type="flex" justify="center">
						<Col span={23}>
							<AccessReportFilterCard
								startDate={startDate}
								endDate={endDate}
								dateFormat={dateFormat}
								onDateChanges={this.onDateFilterChange}
								onApplyFilters={this.applyFilters}
								isLoading={isLoading}
							/>
						</Col>
					</Row>

					{ this.renderReportCard(report) }
				</Col>
			</Row>
		)
	}
}

AccessReport.propTypes = {
	report: PropTypes.object,
	fetchState: PropTypes.string.isRequired,
	loadAccessReports: PropTypes.func.isRequired
}

export default AccessReport;