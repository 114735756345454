import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as Actions from './actions';

import AccessReport from './components/AccessReport';

function mapStateToProps (state) {
	const { login, studentAccessReport } = state;
	const { report, fetchState } = studentAccessReport
	return { report, fetchState, login };
}

function mapDispatchToProps (dispatch) {
	return bindActionCreators({
		loadAccessReports: Actions.handleFetchAccessReports
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessReport);
