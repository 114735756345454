import React, { Component } from 'react';
import { Radar } from 'react-chartjs-2';
import DisciplineMap from '../../../discipline-map'

class AbilitiesRadar extends Component {
	render () {
		const { abilities, title='Habilidades', showLegend=false, multiplier=1, decimal=2 } = this.props;

		const options = {
			legend: {
				display: showLegend
			},
			scale: {
				ticks: {
					min: 0,
      				max: 100
				}
			}
		};

		const disicplineIds = Object.keys(abilities);

		const labels = disicplineIds.map(id => DisciplineMap.byId[id].abbreviation);
		const values = disicplineIds.map(id => (abilities[id] * multiplier).toFixed(decimal));

		const radarData = {
			labels,
			datasets: [
				{
					label: title,
					backgroundColor: 'rgba(17, 37, 70, 0.2)',
					borderColor: 'rgba(17, 37, 70, 0.8)',
					pointBackgroundColor: 'rgba(17, 37, 70, 1)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(17, 37, 70, 1)',
					data: values
				},
			]
		};

		return (<Radar data={radarData} options={options} />)
	}
}

export default AbilitiesRadar;