import BaseError from './BaseError';

class UnrecognizedProfileError extends BaseError {
	constructor(profile) {
		const message = `O perfil "${profile}" não é reconhecido`
		const content = `O perfil "${profile}" não é reconhecido`
		const level = 'error'

		super({ message, content, level });

		this.profile = profile;
	}
}

export default UnrecognizedProfileError;