import ServerError from "./errors/ServerError";
import InvalidCredentialsError from './errors/InvalidCredentialsError';
import UnrecognizedProfileError from "./errors/UnrecognizedProfileError";
import ExpiredAccessError from "./errors/ExpiredAccessError";

const Axios = require('axios');
const Lodash = require('lodash');
const Moment = require('moment');

const dateFormat = 'YYYY-MM-DD';
const datetimeFormat = `${dateFormat}_HH-mm-ss`

const API = Axios.create({
	baseURL: '/api',
	timeout: 15000,
	headers: {
		'Content-type': 'application/json'
	},
	crossDomain: true
})

export function signIn ({ email, password, profile }) {
	const credentials = { email, password };

	let url;

	switch(profile) {
		case 'student':
			url = '/signin/students'
			break;
		case 'coordinator':
			url = '/signin/coordinators'
			break;
		default:
			throw new UnrecognizedProfileError(profile)
	}

	return API
		.post(url, credentials)
		.then(({data}) => {
			const { jwt } = data;
			setJWT(jwt);
			return data;
		})
		.catch(() => {
			throw new InvalidCredentialsError()
		})
}

export function getLogin () {
	const opts = options()
		.header(getJWTHeader)
		.build()

	return API
		.get('/signin', opts)
		.then(response => {
			return response;
		})
		.catch(errorHandler)
}

export function getInstitutions () {
	const opts = options()
		.header(getJWTHeader)
		.build()

	return API.get('/institutions', opts)
		.then(({data}) => {
			return data;
		})
		.catch(errorHandler)
}

export function getAccessReports ({ institutions=[], semesters=[], from, until }={}) {
	const opts = options()
		.header(getJWTHeader)
		.filters({ institutions, semesters, from, until })
		.build()

	opts.timeout = 120000

	return API.get('/accessreports', opts)
		.then(({data}) => {
			data.from = Moment(data.from);
			data.until = Moment(data.until);
			return data;
		})
		.catch(errorHandler)
}

export function getStudentAccessReports ({ studentId, from, until }={}) {
	const opts = options()
		.header(getJWTHeader)
		.filters({ from, until })
		.build()

	opts.timeout = 120000

	return API.get(`/accessreports/student/${studentId}`, opts)
		.then(({data}) => {
			data.from = Moment(data.from);
			data.until = Moment(data.until);
			return data;
		})
		.catch(errorHandler)
}

export function signOut () {
	clearJWT()
}

export function hasCredentials () {
	return ![null, undefined].includes(getJWT());
}

///////////////////////////////////////////////////////
//                                                   //
//       AUX                                         //
//                                                   //
///////////////////////////////////////////////////////

function errorHandler (error) {
	const errorJSON = error.response.data;

	if (errorJSON.status >= 500) {
		throw new ServerError();
	}

	if(errorJSON.status === 401 && errorJSON.detail === 'AccessExpiredError') {
		throw new ExpiredAccessError();
	}

	throw Object.assign(new Error(error.message), errorJSON);
}

function getJWTAuthHeader (token) {
	return { Authorization: `Bearer ${token}` }
}

function setJWT (jwt){
	window.localStorage.setItem('jwt', jwt);
}

function getJWT () {
	return window.localStorage.getItem('jwt')
}

function clearJWT (){
	window.localStorage.removeItem('jwt');
}

function getJWTHeader () {
	return getJWTAuthHeader(getJWT())
}

function options () {
	const options = {};

	options.header = (headerfn) => {
		options.headers = {
			...options.headers,
			...(headerfn())
		}
		return options
	}

	options.build = () => {
		delete options.header
		delete options.build
		return options;
	}

	options.filters = (filters) => {
		if (!options.params) { options.params = {} }

		if (filters) {
			const keys = Object.keys(filters);

			const formatedFilters = keys.map(key => {
				let value = filters[key];

				if (Lodash.isArray(value)) {
					if (value.length === 0) {
						return null;
					}
					value = value.join(',');
				} else if (Lodash.isDate(value) || Moment.isMoment(value)) {
					value = Moment(value).format(datetimeFormat);
				} else if (Lodash.isBoolean(value)) {
					value = value.toString();
				} else if ([null, undefined, ''].includes(value)) {
					return null;
				}

				return { [`filter[${key}]`]: value }
			}).filter(Boolean)

			options.params = Object.assign({}, options.params, ...formatedFilters);
		}

		return options;
	}

	return options;
}