import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as State from '../../request-states';
import { handleFetchAccessReports } from "./actions";
import { handleFetchAccessReports as handleFetchIndividualAccessReport } from '../student-access-report/actions';
import AccessReport from './components/AccessReport';

function mapStateToProps(state) {
	const { login, coordinatorAccessReports, studentAccessReport } = state;

	const institutions = Object.values(state.institutions.byId);

	const isLoadingAccessReports = coordinatorAccessReports.fetchState === State.LOADING;
	const hasLoadedAccessReports = coordinatorAccessReports.fetchState === State.SUCCESS;

	const isLoading = isLoadingAccessReports;
	const hasLoaded = hasLoadedAccessReports;

	const individualAccessReport = studentAccessReport.report;
	const isLoadingIndividualAccessReport = studentAccessReport.fetchState === State.LOADING;

	return {
		login,
		institutions,
		coordinatorAccessReports,
		isLoading,
		hasLoaded,
		individualAccessReport,
		isLoadingIndividualAccessReport
	};
}

function mapDispatchToProps (dispatch) {
	return bindActionCreators({
		loadAccessReports: handleFetchAccessReports,
		loadInvidiualAccessReport: handleFetchIndividualAccessReport
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessReport);
