import React, { Component } from 'react';
import { Card, Table, Form, Input, Button, Modal, Spin } from 'antd';
import { CSVLink } from "react-csv";
import IndividualStudentReport from '../../student-access-report/components/AccessReportCard';

class Filter extends Component {
	handleSubmit = event => {
		event.preventDefault();
		const { onFilter=()=>{}, form } = this.props;
		onFilter(form.getFieldValue('filter'))
	}

	handleClear = event => {
		event.preventDefault();
		const { form, onClear } = this.props;
		form.resetFields();
		onClear();
	}

	renderFilterInput () {
		const { getFieldDecorator } = this.props.form;
		const input = (<Input placeholder="Digite aqui o filtro..." />);
		return getFieldDecorator('filter', [])(input)
	}

	render () {
		return (
			<Form layout="inline" onSubmit={this.handleSubmit}>
				<Form.Item>
					{this.renderFilterInput()}
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						Filtrar
					</Button>
				</Form.Item>

				<Form.Item>
					<Button type="secondary" onClick={this.handleClear}>
						Limpar filtros
					</Button>
				</Form.Item>
			</Form>
		)
	}
}

const FilterForm = Form.create({ name: 'filter-form' })(Filter);

class AccessReportStudentList extends Component {
	constructor (props) {
		super(props);

		this.state = {
			filter: '',
			isStudentModalOpened: false,
			selectedStudentId: null
		}
	}

	applyFilter = (value) => {
		this.setState({ filter: value.toLowerCase() })
	}

	clearFilter = () => {
		this.setState({ filter: '' })
	}

	
	onStudentClick = (record) => {
		const { report } = this.props;
		this.props.loadInvidiualAccessReport({
			from: report.from.toISOString(),
			until: report.until.toISOString(),
			studentId: record.key
		});

		this.setState({
			isStudentModalOpened: true
		});
	}

	renderCsvLink = (filteredData) => {
		const headers = [
			{ label: 'Nome', key: 'fullName' },
			{ label: 'E-mail', key: 'email' },
			// { label: 'Total de acessos no período', key: 'ratio' }, // Removido, pois o job que sincroniza está desativado, pois causa lentidão no ES.
			//{ label: 'Planos de estudos realizados no período', key: 'amount' },
			//{ label: 'Quantidade de metas realizadas - Plano de Estudo', key: 'goalClicks' },
			//{ label: 'Quantidade de metas realizadas - Smart Coach - Questões', key: 'smartCoachGoalsDone' },
			//{ label: 'Total de metas realizadas', key: 'totalGoalsCount' },
			//{ label: 'Média de acertos', key: 'scoreAverage' },
			{ label: 'Melhor Nota no Teste de 43 questões', key: 'bestScore' },
			{ label: 'Chance de Aprovação (%)', key: 'approvalChance' },
			{ label: 'Estimativa de acertos na prova', key: 'expectedHits' },
			{ label: 'Turmas', key: 'classes' }
		];

		return  (
			<CSVLink data={filteredData} headers={headers} separator={";"} filename="alunos-exportados.csv">
				Fazer download do relatório
			</CSVLink>
		);
	}

	renderStudentModal = () => {

		const { isStudentModalOpened } = this.state;
		const { individualAccessReport, isLoadingIndividualAccessReport } = this.props;

		const modalFooter = (
			<Button
				type="primary"
				onClick={() => this.setState({ isStudentModalOpened: false })}
			>
				OK
			</Button>
		);

		return (
			<Modal
				visible={isStudentModalOpened}
				footer={modalFooter}
				width='95%'
				centered
				closable
				onCancel={() => this.setState({ isStudentModalOpened: false })}
			>
				{isLoadingIndividualAccessReport || individualAccessReport === null || individualAccessReport === undefined
					? <div style={{ display: 'flex', justifyContent: 'center' }}><Spin /></div>
					:	<IndividualStudentReport report={individualAccessReport} />
				}
			
			</Modal>
		);
	}

	render () {
		const { report } = this.props;

		const sortString = attr => (a,b) => a[attr].localeCompare(b[attr]);
		const sortNumeric = attr => (a,b) => a[attr] - b[attr];

		const columns = [
			{
				title: 'Nome',
				dataIndex: 'fullName',
				key: 'fullName',
				sorter: sortString('fullName')
			},
			{ title: 'E-mail', dataIndex: 'email', key: 'email', sorter: sortString('email') },
			// { title: 'Total de acessos no período', dataIndex: 'ratio', key: 'ratio', sorter: sortNumeric('accesses'), defaultSortOrder: 'descend' }, Removido, pois o job que sincroniza está desativado, pois causa lentidão no ES.
			// { title: 'Planos de estudos realizados no período', dataIndex: 'amount', key: 'amount', sorter: sortNumeric('amount') },
			// { title: 'Quantidade de metas realizadas - Plano de Estudo', dataIndex: 'goalClicks', key: 'goalClicks', sorter: sortNumeric('goalClicks') },
			// { title: 'Quantidade de metas realizadas - Smart Coach - Questões', dataIndex: 'smartCoachGoalsDone', key: 'smartCoachGoalsDone', sorter: sortNumeric('smartCoachGoalsDone') },
			// { title: 'Total de metas realizadas', dataIndex: 'totalGoalsCount', key: 'totalGoalsCount', sorter: sortNumeric('totalGoalsCount')  },
			// { title: 'Média de acertos', dataIndex: 'scoreAverage', key: 'scoreAverage', sorter: sortNumeric('scoreAverage') },
			{ title: 'Melhor Nota no Teste de 43 questões', dataIndex: 'bestScore', key: 'bestScore', sorter: sortNumeric('bestScore') },
			{ title: 'Chance de Aprovação (%)', dataIndex: 'approvalChance', key: 'approvalChance', sorter: sortNumeric('approvalChance') },
			{ title: 'Estimativa de acertos na prova', dataIndex: 'expectedHits', key: 'expectedHits', sorter: sortNumeric('expectedHits') },
			{ title: 'Turmas', dataIndex: 'classes', key: 'classes', sorter: sortString('classes') }
		];

		const data = report.students.filter(student => (typeof student.classes !== undefined && student.classes !== ''))
			.map(student => {
				const accessData = report.appAccessReport.students[student.id];
				const performanceData = report.customStudyPlanPerformanceReport.students[student.id]
				const goalClickData = report.goalClickReport.students[student.id];
				const smartCoachGoalsDoneData = report.smartCoachGoalDoneReport.students[student.id];

				const ratio = `${accessData.accesses} (${(accessData.ratio * 100).toFixed(0)}%)`;
				const amount = performanceData.amount;
				const scoreAverage = performanceData.mean.score;
				const approvalChance = (performanceData.approvalChance * 100).toFixed(0)
				const expectedHits = performanceData.expected.mode;
				const goalClicks = goalClickData.accesses;
				const smartCoachGoalsDone = smartCoachGoalsDoneData.amount;
				const bestScore = performanceData.bestScore;

				const totalGoalsCount = smartCoachGoalsDone + goalClicks;

				return {
					key: student.id,
					email: student.email,
					fullName: student.fullName,
					classes: student.classes,
					ratio,
					accesses: accessData.accesses,
					amount,
					scoreAverage,
					approvalChance,
					expectedHits,
					goalClicks,
					smartCoachGoalsDone,
					totalGoalsCount,
					bestScore
				}
			})
			.filter(student => {
				const { filter } = this.state;
				if (filter) {
					const isFullNameMatched = student.fullName.toLowerCase().includes(filter);
					const isEmailMatched = student.email.toLowerCase().includes(filter);

					return  isFullNameMatched || isEmailMatched;
				}

				return true;
			})

		return (
			<React.Fragment>
				<Card>
					<h2>Relatórios individuais dos estudantes</h2>
					<FilterForm onFilter={this.applyFilter} onClear={this.clearFilter} />
					<Table
						rowClassName={() => "studentRow"}
						bordered
						columns={columns}
						dataSource={data}
						footer={() => this.renderCsvLink(data)}
						onRow={(record) => ({ onClick: () => this.onStudentClick(record) })}
					/>
				</Card>
				{this.renderStudentModal()}
			</React.Fragment>
		)
	}
}

export default AccessReportStudentList;