const LEVELS = {
	ERROR: 'error',
	WARN: 'warning',
	INFO: 'info',
}

const CONTEXTS = {
	MODAL: 'modal',
	MESSAGE: 'message',
}

class BaseError extends Error {
	constructor({message, title, content, context='modal', level='info', messageDuration=3}={}) {
		super(message);

		this.title = title;
		this.content = content;
		this.context = context;
		this.level = level;
		this.messageDuration = messageDuration;

		this.isModal = false;
		this.isMessage = false;
		this.isError = false;
		this.isWarn = false;
		this.isInfo = false;

		this.update();
	}

	update () {
		this.isModal = this.context === CONTEXTS.MODAL;
		this.isMessage = this.context === CONTEXTS.MESSAGE;
		this.isError = this.level === LEVELS.ERROR;
		this.isWarn = this.level === LEVELS.WARN;
		this.isInfo = this.level === LEVELS.INFO;
	}

	setModal () {
		this.context = CONTEXTS.MODAL;
		this.update();
	}

	setMessage () {
		this.context = CONTEXTS.MESSAGE;
		this.update();
	}

	setLevelInfo () {
		this.level = LEVELS.INFO;
		this.update();
	}

	setLevelWarn () {
		this.level = LEVELS.WARN;
		this.update();
	}

	setLevelError () {
		this.level = LEVELS.ERROR;
		this.update();
	}

	getLevel () { return this.level }
}

BaseError.LEVELS = LEVELS;
BaseError.CONTEXTS = CONTEXTS;

export default BaseError;