import React from 'react';
import BaseError from './BaseError';

class ServerError extends BaseError {
	constructor() {
		const title = 'Ocorreu um erro no servidor'
		const message = 'Erro de servidor'
		const content = (<p>Por favor, espere alguns minutos e tente novamente mais tarde.</p>)
		const level = 'error'

		super({ title, message, content, level });
	}
}

export default ServerError;