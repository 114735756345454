import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Login from './components/Login';

import * as Actions from './actions';
import * as AppActions from '../app/actions';

function mapStateToProps(state) {
	return {
		loginState: state.login.loginState,
		loginError: state.login.loginError
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		submit: Actions.handleLogin,
		closeAllErrors: AppActions.closeAll
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);