const authErrorDetails = [
	'session-expired-error',
	'session-not-found-error'
]

const authCheckerMW = store => next => action => {
	if (action.error) {
		const { error } = action;
		const { title, code, message, details } = error;

		if (authErrorDetails.includes(details)) {
			console.error(title, code, message, details);
			return next(action);
		}
	}

	next(action);
}

export default authCheckerMW;