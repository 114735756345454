import dev from './dev';
import prod from './prod';

let env = process.env['NODE_ENV'] ?
	process.env['NODE_ENV'].trim().toUpperCase() :
	'DEVELOPMENT'
;

const store = env === 'PRODUCTION' ? prod() : dev();

export default store;