import { Layout, Icon, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import TopNavBar from '../../top-nav-bar/components/TopNavBar';
import StudentAccessReport from '../../student-access-report/container'

import '../style.css';

const { Header, Content } = Layout;

class Home extends Component {
	logout = () => {
		this.props.logout();
	}

	renderLoading = () => {
		const icon = <Icon type="loading" style={{ fontSize: 36 }} spin />;
		return (
			<div>
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<Spin indicator={icon} />
				</div>
				<h4 style={{marginTop: '30px', textAlign: 'center'}}>Carregando...</h4>
			</div>
		)
	}

	renderHome = () => {
		return <Redirect to="/access-report" />
	}

	renderContent = () => {
		if (this.props.isLoading) {
			return this.renderLoading()
		}

		return (
			<Switch>
				<Route exact path="/access-report" component={StudentAccessReport} />
				<Route path="/" render={this.renderHome} />
			</Switch>
		)
	}

	render () {
		const { authedUser } = this.props;

		return (
			<Layout style={{minHeight: 'inherit'}}>
				<Header className="header">
					<TopNavBar name={authedUser.name} logout={this.logout} />
				</Header>
				<Content className="content">
					{this.renderContent()}
				</Content>
			</Layout>
		)
	}
};

Home.propTypes = {
	authedUser: PropTypes.object,
	logout: PropTypes.func.isRequired
}

export default Home;