import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Card, Empty, Table, Icon } from 'antd';
import Lodash from 'lodash';

import AbilitiesRadar from '../../abilities-radar/components/AbilitiesRadar';

class AccessReportCard extends Component {
	renderAbilitiesRadar (abilities=null) {
		
		if (abilities && Object.keys(abilities).length > 0) {
			return <AbilitiesRadar abilities={abilities} multiplier={100} decimal={2} />
		}

		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sem dados" />
	}

	render () {
		const { report, dateFormat = 'DD/MM/YYYY' } = this.props;
		const { student, studyPlans, goalsAndEvents } = report;

		const goalsColumns = [
			{ 
				title: 'Plano',
				dataIndex: 'plan',
				key: 'plan'
			},
			{ 
				title: 'Disciplina',
				dataIndex: 'discipline',
				key: 'discipline'
			},
			{ 
				title: 'Data',
				dataIndex: 'studyDate',
				key: 'studyDate'
			},
			{
				title: 'Meta realizada',
				render: (done) => 
					<Icon
						type={done ? 'check' : 'close'}
						style={{ fontSize: 30, color:done ? 'green' : 'red' }}
					/>,
				key: 'done',
				dataIndex: 'done'
			}
		];

		let goalsData = goalsAndEvents.goals.map((goal) => ({
			studyDate: Moment(goal.studyDate).utc().format(dateFormat),
			discipline: goal.discipline,
			plan: goal.plan,
			done: goalsAndEvents.events.find(event => 
				event.GoalId === goal.GoalId
			) !== undefined
		}));
		goalsData = Lodash.orderBy(goalsData, [(row) => Moment(row.studyDate, 'DD/MM/YYYY').format('YYYYMMDD'), 'plan'], ['desc', 'desc']);

		return (
			<Card bodyStyle={{ paddingBottom: 5 }}>
				<Row>
					<Col span={24}>
						<h2>Relatório de {student.fullName}</h2>
						<p>De {Moment(report.from).format(dateFormat)} até {Moment(report.until).format(dateFormat)}</p>
					</Col>
				</Row>

				<Row style={{marginTop: 20, textAlign: 'center'}}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{marginTop: 20}}>
						<h3 style={{textAlign: 'center', marginBottom: 20}}>Resumo*</h3>
						<h4>Melhor Nota no Teste de 43 questões: {studyPlans.bestScore} questões</h4>
						<h4>Chance de aprovação: {(studyPlans.approvalChance * 100).toFixed(0)}%</h4>
						<h4>Estimativa de acertos na prova: {studyPlans.expectedScore.mode} questões</h4>
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						<h5>OBS: A partir do semestre 2022.2 a avaliação será com base na melhor nota obtida no teste de 43 questões do plano de estudo. Para mais informações acesse <a href="https://www.oabdebolso.com/aluno-ser/">www.oabdebolso.com/aluno-ser</a></h5>

						{/* <h4>Quantidade de planos no período: {studyPlans.amount} exames</h4> */}
						{/* <h4>Quantidade de acessos no período: {report.accesses.amount} dias</h4> */} {/* Removido, pois o job que sincroniza está desativado, pois causa lentidão no ES. */} 
						{/* <h4>Quantidade de metas realizadas no período - Plano de estudo: {report.goalClicks.amount} metas</h4> */}
						{/* <h4>Quantidade de metas realizadas no período - Smart Coach - Questões: {report.smartCoachGoalsDone.amount} metas</h4> */}
						{/* <h4>Total de metas realizadas no período: {report.goalClicks.amount + report.smartCoachGoalsDone.amount}</h4> */}
					</Col>

					<Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{marginTop: 20}}>
						<h3 style={{textAlign: 'center', marginBottom: 20}}>Radar de habilidades</h3>
						{ this.renderAbilitiesRadar(studyPlans.abilities) }
					</Col>

					{/* <Col span={24} style={{marginTop: 20, textAlign: 'left'}}>
						<span style={{ color: 'red' }}>*Este relatório pode demorar cerca de 3 horas para atualizar as ações que você realizou pelo OAB de Bolso (quantidades de metas, planos e acessos).</span>
					</Col> */}
				</Row>
				{/* <Row type="flex" justify="space-around" style={{ marginTop: 20 }}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<h3>Lista de Metas</h3>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Table
							bordered
							pagination={false}
							columns={goalsColumns}
							dataSource={goalsData}
							locale={{ emptyText: 'Nenhuma meta para o período selecionado' }}
						/>
					</Col>
				</Row> */}
			</Card>
		)
	}
}

AccessReportCard.propTypes = {
	report: PropTypes.object.isRequired,
	dateFormat: PropTypes.string,
}

export default AccessReportCard;
