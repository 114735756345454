import React, { Component } from 'react';
import { Card, List, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import Lodash from 'lodash';

class AccessReportSubjectsCard extends Component {
	renderSubjectsList = (disciplineMap, subjectsByDisciplineId) => {
		const data = Object.keys(disciplineMap)
			.map(disciplineId => {
				return {
					id: disciplineId,
					name: disciplineMap[disciplineId].name,
					subjects: subjectsByDisciplineId[disciplineId]
				}
			})

		const grid = {
			xs: 24,
			sm: 24,
			md: 24,
			lg: 12,
			xl: 12,
			xxl: 8,
		}

		const getSubjectText = (subject) => {
			return `${subject.name} (${subject.recommendations} ocorrência${subject.recommendations > 1 ? 's' : ''})`;
		}

		const style = {
			marginTop: 20,
			paddingLeft: 10,
			paddingRight: 10
		}

		return (
			<Row type="flex">
				{
					data.map(item => (
						<Col key={item.id} {...grid} style={style}>
							<Card title={item.name}>
								<List
									size="small"
									dataSource={item.subjects}
									renderItem={item => (
										<List.Item>
											{ getSubjectText(item) }
										</List.Item>
									)}
								/>
							</Card>
						</Col>
					))
				}
			</Row>
		)
	}

	render () {
		const {
			amount=5,
			subjects=[],
			disciplineMap={},
		} = this.props;

		const subjectsByDisciplineId = Lodash.groupBy(subjects, subject => subject.DisciplineId)

		Object.keys(subjectsByDisciplineId)
			.forEach(disciplineId => {
				subjectsByDisciplineId[disciplineId] = subjectsByDisciplineId[disciplineId]
					.sort((a, b) => b.recommendation - a.recommendation)
					.slice(0, amount);
			});

		return (
			<Card>
				<h2>Assuntos mais recomendados por disciplina</h2>
				{ this.renderSubjectsList(disciplineMap, subjectsByDisciplineId) }
			</Card>
		)
	}
}

AccessReportSubjectsCard.propTypes = {
	amount: PropTypes.number,
	subjects: PropTypes.array,
	disciplineMap: PropTypes.object,
}

export default AccessReportSubjectsCard;