const byId = {
	'0SLmcjPQ1bi16RTwTckZow': {
		name: 'Direito Processual Civil - Novo CPC',
		abbreviation: 'D. P. Civ.'
	},
	'0kiuxPWIjRkYovh9tijSEd': {
		name: 'Direito Civil',
		abbreviation: 'D. Civ.'
	},
	'1GwBKmTP5prMfr6rsB0eDb': {
		name: 'Direito Tributário',
		abbreviation: 'D. Trib.'
	},
	'1e4Doykn6DYnupZKsya8YE': {
		name: 'Direito Processual do Trabalho',
		abbreviation: 'D. P. Trab.'
	},
	'1g19bO6JCwlRIbOGWm5w67': {
		name: 'Direito Internacional',
		abbreviation: 'D. Int.'
	},
	'2Udr6NUzlvcSHDsO14PC97': {
		name: 'Estatuto da Criança e do Adolescente',
		abbreviation: 'ECA'
	},
	'3UmOWCIcEFU3erortMoUxQ': {
		name: 'Ética',
		abbreviation: 'Ética'
	},
	'3brLIqUHP4QKYnnYO1WdFq': {
		name: 'Direito Penal',
		abbreviation: 'D. Pen.'
	},
	'3qRjtIs2d9GdAVAttvewDt': {
		name: 'Direito Processual Penal',
		abbreviation: 'D. P. Pen.'
	},
	'44upuvXo9yJb8L2tRC8gv5': {
		name: 'Direito do Trabalho',
		abbreviation: 'D. Trab.'
	},
	'47IRPvSAMeZEbEo8PorR1Y': {
		name: 'Direito Ambiental',
		abbreviation: 'D. Amb.'
	},
	'4pqclywopyYnzubymKe52w': {
		name: 'Direito Empresarial',
		abbreviation: 'D. Emp.'
	},
	'5oepJhlz632n4BYZDWUTqt': {
		name: 'Direito do Consumidor',
		abbreviation: 'D. Consum.'
	},
	'65gAWzNyA4VpK0v0OFYuHg': {
		name: 'Filosofia do Direito',
		abbreviation: 'Fil. Direito'
	},
	'695etBjGRsDp1AxOLzHIQ0': {
		name: 'Direito Administrativo',
		abbreviation: 'D. Adm.'
	},
	'6NOWn7GOuzvXW0fiJKFHt4': {
		name: 'Direitos Humanos',
		abbreviation: 'D. Hum.'
	},
	'7BHLi56y7MRyVcTYlNg9zh': {
		name: 'Direito Constitucional',
		abbreviation: 'D. Const.'
	}
}

export default { byId }