import 'antd/dist/antd.css'
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LOADING } from '../../../request-states';

import LoginBox from './LoginBox';

class Login extends Component {

	componentWillUnmount () {
		this.props.closeAllErrors();
	}

	handleLogin = ({email, password, profile}) => {
		this.props.submit({email, password, profile})
	}

	render() {
		const { loginState } = this.props;

		return (
			<div style={{minHeight: 'inherit'}}>
				<Row type="flex" justify="center" align="middle" style={{minHeight: 'inherit'}}>
					<Col xs={23} sm={20} md={12} xl={6}>
						<LoginBox
							isLogingIn={loginState === LOADING}
							onLogin={this.handleLogin}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

Login.propTypes = {
	submit: PropTypes.func.isRequired,
	loginState: PropTypes.string.isRequired
}

export default Login;
