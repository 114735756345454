import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';

import '../style.css'

class TopNavBar extends Component {
	handleLogout = (event) => {
		event.preventDefault();
		this.props.logout();
	}

	render() {
		const { name } = this.props;

		return (
			<Row className="top-nav-var" type="flex" justify="end">
				<Col>
					<span className="user-email">Olá, {name}</span>
					<Button onClick={this.handleLogout}>Sair</Button>
				</Col>
			</Row>
		)
	}
}

TopNavBar.propTypes = {
	name: PropTypes.string.isRequired,
	logout: PropTypes.func.isRequired
}

export default TopNavBar;