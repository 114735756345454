import BaseError from './BaseError';

class ExpiredAccessError extends BaseError {
	constructor() {
		const message = 'Acesso expirado';
		const content = 'Seu acesso expirou. Para Continuar utilizando o sistema, faça login novamente.';
		const messageDuration = 15;

		super({ message, content, messageDuration });

		this.setMessage();
		this.setLevelError();

		Object.setPrototypeOf(this, ExpiredAccessError.prototype);
	}
}

export default ExpiredAccessError;