import * as API from '../../REST-API';

export const SET_INSTITUTIONS = 'SET_INSTITUTIONS';
export const RESET_INSTITUTIONS = 'RESET_INSTITUTIONS';
export const FETCH_INSTITUTIONS_START = 'FETCH_INSTITUTIONS_START';
export const FETCH_INSTITUTIONS_SUCCESS = 'FETCH_INSTITUTIONS_SUCCESS';
export const FETCH_INSTITUTIONS_FAIL = 'FETCH_INSTITUTIONS_FAIL';

export function setInstitutions (institutions) {
	return {
		type: SET_INSTITUTIONS,
		institutions
	}
}

export function resetInstitutions () {
	return { type: RESET_INSTITUTIONS }
}

function fetchInstitutionsStart () {
	return { type: FETCH_INSTITUTIONS_START }
}

function fetchInstitutionsSuccess () {
	return { type: FETCH_INSTITUTIONS_SUCCESS }
}

function fetchInstitutionsFail (error) {
	return {
		type: FETCH_INSTITUTIONS_FAIL,
		error
	}
}

export function handleFetchInstitutions () {
	return dispatch => {
		dispatch(fetchInstitutionsStart())
		API.getInstitutions()
			.then(data => {
				dispatch(setInstitutions(data))
				dispatch(fetchInstitutionsSuccess())
			})
			.catch(error => {
				dispatch(fetchInstitutionsFail(error))
			})
	}
}
