import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Home from './components/Home';

import * as LoginActions from '../login/actions';

function mapStateToProps(state) {
	const { authedUser } = state.login;

	return { authedUser }
}

function mapDispatchToProps (dispatch) {
	return bindActionCreators({
		logout: LoginActions.handleLogout
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
