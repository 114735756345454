import Moment from 'moment';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import '../style.css';

import AccessReportFilterCard from './AccessReportFilterCard';
import AccessReportSummaryCard from './AccessReportSummaryCard';
import AccessReportSubjectsCard from './AccessReportSubjectsCard';
import AccessReportStudentList from './AccessReportStudentList';

import DisciplineMap from '../../../discipline-map';

const dateFormat = 'DD/MM/YYYY';

class AccessReport extends Component {
	constructor (props) {
		super(props);

		this.state = {
			filters: {
				institution: null,
				semester: null,
				startDate: Moment().subtract(1, 'month'),
				endDate: Moment()
			}
		}
	}

	applyReportFilter = (key, value) => {
		this.setState(prev => {
			const { filters } = this.state;
			filters[key] = value;
			return { filters };
		})
	}

	applyFilters = () => {
		const { institution, startDate, endDate } = this.state.filters;

		const institutions = institution ? [institution.id] : null;
		const from = startDate.toISOString();
		const until = endDate.toISOString();

		this.props.loadAccessReports({ institutions, from, until });
	}

	isDisableSearch = () => {
		const { institution, startDate, endDate } = this.state.filters;
		return (
			[null, undefined].includes(institution) ||
			[null, undefined].includes(startDate) ||
			[null, undefined].includes(endDate) ||
			startDate >= endDate
		);
	}

	renderAccessReportSummaryCard = (report) => {
		const {
			appAccessReport = null,
			customStudyPlanPerformanceReport = null
		} = report;

		let meanAbilities = {};

		if (customStudyPlanPerformanceReport) {
			meanAbilities = customStudyPlanPerformanceReport.summary.averages.abilities;
		}

		return (
			<Row type="flex" justify="center" style={{marginTop: "10px"}} >
				<Col span={23}>
					<AccessReportSummaryCard
						range={report ? report.range : 0}
						startDate={Moment(report.from).format('DD/MM/YYYY')}
						finalDate={Moment(report.until).format('DD/MM/YYYY')}
						studentAmount={report.students.length}
						meanAppAccessDays={appAccessReport ? appAccessReport.mean.accesses || 0 : 0}
						meanAppAccessRatio={appAccessReport ? appAccessReport.mean.ratio : 0}
						meanAbilities={meanAbilities}
						expectedApprocedAmount={report.customStudyPlanPerformanceReport.summary.expectedApproved}
					/>
				</Col>
			</Row>
		)
	}

	renderAccessReportStudentList = (report) => {
		return (
			<Row type="flex" justify="center" style={{marginTop: "10px"}} >
				<Col span={23}>
					<AccessReportStudentList
						report={report}
						loadInvidiualAccessReport={this.props.loadInvidiualAccessReport}	
						isLoadingIndividualAccessReport={this.props.isLoadingIndividualAccessReport}
						individualAccessReport={this.props.individualAccessReport}
					/>
				</Col>
			</Row>
		)
	}

	renderAccessReportSubjectsCard = (report) => {
		const { subjects } = report.customStudyPlanPerformanceReport;

		return (
			<Row type="flex" justify="center" style={{marginTop: "10px"}} >
				<Col span={23}>
					<AccessReportSubjectsCard subjects={subjects} disciplineMap={DisciplineMap.byId} />
				</Col>
			</Row>
		)
	}

	render() {
		const semesters = [7,8,9,10];
		const { institution, semester, startDate, endDate } = this.state.filters;
		const { isLoading, institutions, coordinatorAccessReports } = this.props;

		const { report } = coordinatorAccessReports;

		return (
			<Row style={{minHeight: 'inherit'}}>
				<Col span={24}>
					<Row>
						<Col xs={24}>
							<h1>Relatório Institucional e de Acesso</h1>
						</Col>
					</Row>
					<Row type="flex" justify="center" style={{marginTop: "10px"}} >
						<Col span={23}>
							<AccessReportFilterCard
								institution={institution}
								semester={semester}
								startDate={startDate}
								endDate={endDate}

								institutions={institutions}
								semesters={semesters}

								onApplyFilters={this.applyFilters}
								onInstitutionChange={(value) => this.applyReportFilter('institution', value)}
								onSemesterChange={(value) => this.applyReportFilter('semester', value)}
								onStartDateChange={(value) => this.applyReportFilter('startDate', value)}
								onEndDateChange={(value) => this.applyReportFilter('endDate', value)}

								dateFormat={dateFormat}
								isLoading={isLoading}
								disabled={this.isDisableSearch()}
							/>
						</Col>
					</Row>
					{ report ? this.renderAccessReportSummaryCard(report) : '' }
					{ report ? this.renderAccessReportStudentList(report) : '' }
					{ report ? this.renderAccessReportSubjectsCard(report) : '' }
				</Col>
			</Row>
		)
	}
}

AccessReport.propTypes = {
	login: PropTypes.shape({
		authedCoordinator: PropTypes.object,
		login: PropTypes.object,
		loginError: PropTypes.object,
		loginState: PropTypes.string
	}),
	institutions: PropTypes.array.isRequired,
}

export default AccessReport;