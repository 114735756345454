import * as API from '../../REST-API';

export const SET_STUDENT_ACCESS_REPORT = 'SET_STUDENT_ACCESS_REPORT';
export const RESET_STUDENT_ACCESS_REPORT = 'RESET_STUDENT_ACCESS_REPORT';
export const FETCH_STUDENT_ACCESS_REPORT_START = 'FETCH_STUDENT_ACCESS_REPORT_START';
export const FETCH_STUDENT_ACCESS_REPORT_SUCCESS = 'FETCH_STUDENT_ACCESS_REPORT_SUCCESS';
export const FETCH_STUDENT_ACCESS_REPORT_FAIL = 'FETCH_STUDENT_ACCESS_REPORT_FAIL';

export function setAccessReports (accessReport) {
	return {
		type: SET_STUDENT_ACCESS_REPORT,
		accessReport
	}
}

export function resetAccessReports () {
	return { type: RESET_STUDENT_ACCESS_REPORT }
}

function fetchAccessReportsStart () {
	return { type: FETCH_STUDENT_ACCESS_REPORT_START }
}

function fetchAccessReportsSuccess () {
	return { type: FETCH_STUDENT_ACCESS_REPORT_SUCCESS }
}

function fetchAccessReportsFail (error) {
	return {
		type: FETCH_STUDENT_ACCESS_REPORT_FAIL,
		error
	}
}

export function handleFetchAccessReports ({ studentId, from, until }={}) {
	return dispatch => {
		dispatch(resetAccessReports());
		dispatch(fetchAccessReportsStart())
		API.getStudentAccessReports({ studentId, from, until })
			.then(accessReport => {
				dispatch(setAccessReports(accessReport))
				dispatch(fetchAccessReportsSuccess())
			})
			.catch(error => {
				dispatch(fetchAccessReportsFail(error))
			})
	}
}
